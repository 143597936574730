import React from "react"
import { Link } from "gatsby"

import grid from '../../scss/flexboxgrid.module.scss'
import view from './footer.module.scss'
import CookiePolicy from "../cookie/CookiePolicy"

function Footer(props) {
  const { dealerInfo, footer,footerClassName} = props
  const bottomLinks = footer?.bottomLinks

  const renderLinks = () => (
    <div className={view["foot-cont"]}>
      {
        footer.links.map((item, idx) => (
          <Link id='nav_link' key={idx} className={`${view["btn-text"]}`}
                to={item.linkValue}>
            <span>{item.linkName}</span>
          </Link>
        ))
      }
    </div>
  )

  const renderSocialColumn = () => (
    <div className={view["social-cont"]}>
      <div className={view['social-icon-list']}>
        {footer.linkFB ? <Link to={footer.linkFB} className={`${view["social-icon"]} ${view["fb"]}`} /> : null}
        {footer.linkInsta ? <Link to={footer.linkInsta} className={`${view["social-icon"]} ${view["insta"]}`} /> : null}
        {footer.linkTwit ? <Link to={footer.linkTwit} className={`${view["social-icon"]} ${view["twitter"]}`} /> : null}
        {footer.linkYelp ? <Link to={footer.linkYelp} className={`${view["social-icon"]} ${view["yelp"]}`} /> : null}
        {footer.linkYT ? <Link to={footer.linkYT} className={`${view["social-icon"]} ${view["youtube"]}`} /> : null}
      </div>
    </div>
  );
    
  const renderBottomLinks = () => {
    return <div className={view["bottom-links-cont"]}>
            {bottomLinks.map(link => {
              const  name = link.linkName
              const value = link.linkValue
              return <Link to={value} className={view["bottom-link"]}>{name}</Link>
            })}
          </div>
  }
  const footerLogo = props.dealerInfo.dealerLogo
  return (
    <footer id="footer" className={view["footer"] + " " + (footerClassName === undefined ? "" : footerClassName)}>
      <div className={view["amb89Logo"]}>
        <a href="/">
          <img src={footerLogo} alt={props.dealerInfo.dealerName} />
        </a>
      </div>
      <div className={`${grid["row"]} ${view["foot-wrap"]}`}>
        <div className={`${grid["col-lg-12"]} ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]} `}>
          {renderLinks()}  
        </div>
        <div className={`${grid["col-lg-12"]}  ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]}`} style={{ textAlign: "center" }}>
       
        
          <div className={view["cookieAndDealerMasterWrapper"]}>
          <Link id='nav_link' to={"https://dealermasters.com/"} className={view["subtitle"]}>
              <div className={view["dealerMasterSectionContainer"]}>
                  <p>&#169; {new Date().getFullYear()} {dealerInfo.dealerName}</p>
                  <p style={{ display: "inline-block" }}>Made With</p>
                   <span className={view["heart"]} aria-label={"heart"} role={"img"}> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="none" stroke="var(--primaryAccent)" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" style={{ verticalAlign: 'middle' }}>
  <path d="M12 21C12 21 7 16.5 5 14C2.5 11.5 2.5 8 5 6C7.5 3.5 11 4 12 6C13 4 16.5 3.5 19 6C21.5 8 21.5 11.5 19 14C17 16.5 12 21 12 21Z" fill="var(--primaryAccent)" />
</svg>
 </span>
                  <p style={{ display: "inline-block" }}>By Dealer Masters</p>
               </div>
               </Link>
               
            <div>
            <div> {renderSocialColumn()}</div>

            <div className={view["cookieAndBelowLinksWrapper"]}>
              {renderBottomLinks()}
            <CookiePolicy dealerInfo={dealerInfo} CookieLinkText="Manage Cookie Policy" />
            </div>
           
          </div>


           
            </div>
       
      

        </div>
      </div>
    </footer>
  )
}

export default Footer
